.login-bar {
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
}

.main-navbar {
    color: #bbbbbb;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
}

.dropdown-toggle::after {
    display: none;
}

.login-link {
    color: "white";
    font-size: "24px";
}

div.dropdown-menu {
    margin: 0 !important;
}
